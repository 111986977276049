<template>
  <div>
    <div v-if="pageReady" class="d-flex justify-content-center">
      <div class="container-fluid mx-5 h-100">
        <div class="row d-flex mx-auto bg-white">
          <ul
            id="tpd-pills-tab"
            class="nav nav-pills d-flex tpd-tabs justify-content-center flex-wrap p-0 overflow-hidden"
          >
            <li
              v-for="(item, idx) in renderTabs"
              :key="item.title"
              class="nav-item d-flex align-items-center justify-content-center flex-grow-1"
            >
              <router-link
                :to="{
                  name: `${item.title}`,
                  params: { id: $route.params.id },
                }"
              >
                <button type="button" class="nav-link referral-tab underline">
                  <div class="number-icon">{{ idx + 1 }}</div>
                  <span class="ms-3">{{ item.display_title }}</span>
                </button>
              </router-link>

              <div v-if="renderTabs.length !== idx + 1" class="chervon">
                <img
                  src="@/assets/svg/chervon-right.svg"
                  alt="chervon-right-icon"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="container-fluid overflow-auto p-5">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const renderTabs = ref([
  {
    title: 'lawyer-referral-creation',
    display_title: 'Referral Creation',
  },
  {
    title: 'lawyer-referral-document-uploads',
    display_title: 'Document Uploads',
  },
  { title: 'lawyer-referral-created', display_title: 'Referral Created' },
])

const pageReady = ref(true)
</script>

<style lang="scss" scoped>
.dropdown-menu {
  z-index: 999;
}

.tpd-tabs {
  .nav-item {
    position: relative;

    .referral-tab {
      border-radius: 0;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 20px;
      text-transform: capitalize;
      color: #aeaeae;
      background: white;
    }

    .number-icon {
      border-radius: 50%;
      width: 36px;
      height: 36px;
      padding: 6.5px;
      background: #d9d9d9;
      color: white;
      text-align: center;
    }

    .router-link-exact-active {
      button {
        color: black;
      }

      .number-icon {
        background: #32c3d6;
        color: white;
      }
      .underline {
        border-bottom: 5px solid #32c3d6;
      }
    }
  }

  .chervon {
    margin-left: 20%;
  }
}
</style>
